import request from "@/utils/request";

/**
 * 生成订阅订单
 * @param {*} data 
 * @param { String } environment || PC_Native(PC 网页)=1  H5_App(H5 App 内)=2  H5_Wap(H5 网页)=3
 * @param { String } platform || Wechat(微信支付)=2 Alipay(支付宝支付)=3 CreditCard(信用卡)=4 BankCard(银行卡)=5 Other(其他支付)=99
 * @param { String } packageId || 规格id
 * @returns 
 */
export const generatePackage = (data) => {
  return request({
    url: "/api/app/accountOrder/generate-package",
    method: "post",
    data: {
      ...data,
      environment:1
    },
  });
};

/**
 * 生成单篇订单
 * @param {*} data 
 * @param { String } environment || PC_Native(PC 网页)=1  H5_App(H5 App 内)=2  H5_Wap(H5 网页)=3
 * @param { String } platform || Wechat(微信支付)=2 Alipay(支付宝支付)=3 CreditCard(信用卡)=4 BankCard(银行卡)=5 Other(其他支付)=99
 * @param { String } articleId || 文章id
 * @returns 
 */
export const generateArticle = (data) => {
  return request({
    url: "/api/app/accountOrder/generate-article",
    method: "post",
    data: {
      ...data,
      environment:1
    },
  });
};

/**
 * 轮询查询支付结果
 * @param { String } code || 
 */
export const getPayState = (data) => {
  return request({
    url: "/api/Pay/State",
    method: "get",
    params: {
      code:data
    },
  });
};



/**
 * 我的订单
 * @param {String} page || 页码
 * @param {String} pageSize || 页码大小
 * @param {String} orderNo || 订单号
 * @param {String} states || 订单状态 0 1 2 3
 */
export const getMyOrder = (data) => {
  return request({
    url: "/api/app/accountOrder/query",
    method: "post",
    data: data,
  });
};

/**
 * 获取用户支付协议
 */
export const getPayAgreement = () => {
  return request({
    url: "/api/app/info/PayAgreement",
    method: "get"
  });
};
